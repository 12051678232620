import { CSSProperties } from "react"
import { styled } from "styled-components"

type ContainerProps = {
  hidden?: string
  containerType?: CSSProperties["containerType"]
  children: React.ReactNode
} & CSSProperties

export function Container({
  containerType = "inline-size",
  hidden,
  children,
  translate: _translate, // doesn't work with the typing for some reason 🤷, hopefully we won't need it
  ...props
}: ContainerProps) {
  if (hidden) return null

  return (
    <Box $containerType={containerType} {...props}>
      {children}
    </Box>
  )
}

const Box = styled.div<{ $containerType: ContainerProps["containerType"] }>`
  container-type: ${({ $containerType: containerType }) => containerType};
`
